<script lang="ts" setup>
import { ref } from 'vue'

const visible = ref(true)

defineExpose({ visible })
</script>

<template>
  <div id="app-loading-container">
    <Transition name="preloader" mode="out-in">
      <div v-if="visible" id="app-loading">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" />
        </svg>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
.preloader-enter-active,
.preloader-leave-active {
  transition: opacity $default-duration;
}

.preloader-enter-from,
.preloader-leave-to {
  opacity: 0;
}

#app-loading {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: color('white');
  color: color('black');

  .spinner {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    animation: preloader-rotate 2s linear infinite;

    .path {
      animation: preloader-dash 1.5s ease-in-out infinite;
      stroke: currentColor;
      stroke-linecap: round;
    }
  }
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloader-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
