/**
 * First file imported by the app
 *
 * This should NOT contain ANY dependency
 * except the ones necessary to show the preloader
 * so be careful
 */
import type { ComponentInstance } from 'vue'
import { createApp } from 'vue'

import Preloader from './Preloader.vue'

export type PreloaderPublicInstance = ComponentInstance<typeof Preloader>

const preloaderApp = createApp(Preloader)

if (!('__PRERENDER_INJECTED' in window)) {
  // horrible hack (TM)
  window.preloaderInstance = preloaderApp.mount('#app-loading-container') as PreloaderPublicInstance
}

import('./main.ts')
  // .then(main => main)
  .catch(e => console.error(e))
